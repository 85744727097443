import App from "./App.svelte";

let app;
let targetElement = document.querySelector("#chipp-chat-widget");
// If the element doesn't exist, create the element and append it to the body
if (!targetElement) {
  const newElement = document.createElement("div");
  newElement.id = "chipp-chat-widget";
  document.body.appendChild(newElement);
  targetElement = newElement;
}

if (!targetElement.__svelte_app) {
  app = new App({
    target: targetElement,
    props: {
      appUrl: window.CHIPP_APP_URL || "http://localhost:3001",
      appId: window.CHIPP_APP_ID || 5,
    },
  });
  targetElement.__svelte_app = app;
} else {
  app = targetElement.__svelte_app;
}

export default app;
