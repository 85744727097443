<script>
  export let logoUrl;
  export let name;
</script>

<img
  src={logoUrl}
  alt={name}
  width={28}
  height={28}
  style="margin-right: 8px;"
/>
