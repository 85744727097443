<script>
  export let appUrl;
  export let visible;

  import { spring } from "svelte/motion";

  let scale = spring(0.75, { stiffness: 0.05, damping: 0.4 });
  let translateY = spring(600, { stiffness: 0.05, damping: 0.4 });
  let opacity = spring(0, { stiffness: 0.1, damping: 0.4 });
  let pointerEvents = "none";

  function updateAnimations() {
    scale.set(visible ? 1 : 0.75);
    translateY.set(visible ? 0 : 600);
    opacity.set(visible ? 1 : 0);
    pointerEvents = visible ? "auto" : "none";
  }

  $: visible, updateAnimations();
</script>

<iframe
  src={`${appUrl}?isChatEmbed=true`}
  title="Chipp Chat Widget"
  id="chipp-chat-widget-full"
  style="transform: scale({$scale}) translateY({$translateY}px); 
         opacity: {$opacity}; 
         pointer-events: {pointerEvents};"
/>

<style>
  #chipp-chat-widget-full {
    border: none;
    border-radius: 12px;
    position: fixed;
    z-index: 2147483646;
    bottom: 130px;
    right: 25px;
    width: 390px;
    height: 600px;
    transition:
      opacity 150ms ease-in-out,
      transform 150ms ease-in-out;
    box-shadow: 0px 18px 32px -8px rgba(0, 0, 0, 0.12);
  }
  @media (max-width: 768px) {
    #chipp-chat-widget-full {
      left: 16px;
      right: 16px;
      top: 16px;
      bottom: 130px;
      width: calc(100% - 32px);
      height: calc(95% - 130px);
      border: none;
    }
  }
</style>
